export const ABOUT_INSTANT_DATA_TABLE = [
    {
        match: 10,
        prize: '200,000,000 GNF',
    },
    {
        match: 9,
        prize: '10,000,000 GNF',
    },
    {
        match: 8,
        prize: '1,000,000 GNF',
    },
    {
        match: 7,
        prize: '100,000 GNF',
    },
    {
        match: 6,
        prize: '50,000 GNF',
    },
    {
        match: 5,
        prize: '20,000 GNF',
    },
    {
        match: 4,
        prize: '10,000 GNF',
    },
    {
        match: 3,
        prize: '8,000 GNF',
    },
    {
        match: 2,
        prize: '5,000 GNF',
    },
    {
        match: 1,
        prize: '3,000 GNF',
    },
]
